import React, { createContext, useContext, useReducer } from 'react'
import {
  messageReducer,
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  CLEAR_MESSAGES,
} from './message-reducer'
import Theme from './Theme'

// // Initial State
// const initialState = {
//   messages: [],
//   addMessage: message => {},
//   removeMessage: messageId => {},
//   clearMessages: messages => [],
// }

// Create Context
const MessageContext = createContext({})

// Create the Context provider
const MessageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messageReducer, { messages: [] })

  const addMessage = message => {
    dispatch({ type: ADD_MESSAGE, message })
  }

  const removeMessage = messageId => {
    dispatch({ type: REMOVE_MESSAGE, messageId })
  }

  const clearMessages = () => {
    dispatch({ type: CLEAR_MESSAGES })
  }

  return (
    <MessageContext.Provider
      value={{
        messages: state.messages,
        addMessage,
        removeMessage,
        clearMessages,
      }}
    >
      <Theme>{children}</Theme>
    </MessageContext.Provider>
  )
}

// Creates a nice little shorthand for useContext(AuthContext)
const useMessage = () => useContext(MessageContext)

export { useMessage, MessageProvider }
