/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import 'https://use.typekit.net/lyu3wmx.css'
import React from 'react'
import 'tailwindcss/dist/base.min.css'

import tw, { css } from 'twin.macro'
import { Global } from '@emotion/react'
import { root } from './src/components/theme'

import { MessageProvider } from './src/context/message-provider'
import { AuthProvider } from './src/context/auth-provider'
import Messenger from './src/components/Messenger'
import injectScript from './src/utils/injectScript'

// if (typeof window !== 'undefined') {
//   window.googleApiLoaded = false
//   // Callback needs to be defined before we inject the script or else we get a CORS error
//   window.initMap = () => {
//     console.log('Google Maps API is Ready')
//     window.googleApiLoaded = true
//   }
//   injectScript(
//     `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places&callback=initMap`,
//     'googleMapsApi'
//   )
// }

export const wrapRootElement = ({ element }) => (
  <MessageProvider>
    {/* eslint-disable-next-line prettier/prettier */}
    <Global styles={css`${root}`}/>
    <Messenger />
    <AuthProvider>{element}</AuthProvider>
  </MessageProvider>
)
