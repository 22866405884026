export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

const addMessage = (message, state) => {
  const currentMessages = [...state.messages]
  currentMessages.push(message)
  return { ...state, messages: currentMessages }
}

const removeMessage = (messageId, state) => {}

const clearMessages = state => ({ ...state, messages: [] })

export const messageReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return addMessage(action.message, state)
    case 'REMOVE_MESSAGE':
      return removeMessage(action.messageId, state)
    case 'CLEAR_MESSAGES':
      return clearMessages(state)
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
