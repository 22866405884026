module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    fontFamily: {
      sans: ['proxima-soft', 'sans-serif', '"Helvetica Neue"', 'Arial'],
    },
    fontWeight: {
      normal: '400',
      semibold: '600',
      bold: '700',
    },
    fontSize: {
      xs: '0.75rem',
      sm: '1rem',
      base: '1.125rem',
      lg: '1.25rem',
      xl: '1.5rem',
      '2xl': '1.75rem',
      '3xl': '2rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '8xl': '5rem',
    },
    colors: {
      DEFAULT: '#171E2D',
      transparent: 'transparent',
      current: 'currentColor',
      white: '#fff',
      purple: {
        DEFAULT: '#6E2979',
        light: '#983794',
        lighter: '#B672AE',
        lightest: '#DAC9DD',
      },
      green: {
        DEFAULT: '#4EA900',
        dark: '#458606',
      },
      gray: {
        100: '#f5f7fd',
        150: '#e3e7eb',
        200: '#D3D7DB',
        300: '#B3B7BA',
        400: '#919498',
      },
      red: '#E53E3E',
    },

    extend: {},
  },
  variants: {},
  plugins: [],
}
