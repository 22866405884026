import React, { createContext, useContext, useState } from 'react'
import { navigate, location } from '@reach/router'

const AuthContext = createContext({})

async function sendRequest(method = 'GET', endpoint, body, successCallback = () => {}, errorCallback) {
  if (!endpoint) {
    throw new Error('Endpoint was not supplied for fetch action')
  }

  const requestOptions = {
    method,
    headers: {
      Accept: 'application/json',
    },
  }

  if (body) {
    requestOptions.headers['Content-Type'] = 'application/json'
    requestOptions.body = JSON.stringify(body)
  }

  try {
    const res = await fetch(`/.netlify/functions/${endpoint}`, requestOptions)

    if (!res.ok) {
      throw await res.json()
    }

    const resBody = await res.json()
    return successCallback(resBody)
  } catch (error) {
    console.error(error, window.location.href)

    return navigate(`/login?redirect=${window.location.pathname}`)

    // return errorCallback(err)
    // The JWT has expired, redirect user
    return Promise.reject(error)
  }
}

function AuthProvider({ children }) {
  const localUserJson = localStorage.getItem('user')
  const localUser = (localUserJson && localUserJson !== 'undefined' && JSON.parse(localUserJson)) || null
  // const localUser = localUserJson && JSON.parse(localUserJson)
  const [user, setUser] = useState(localUser)

  const saveUser = fetchData => {
    console.log('Saving User to localStorage', fetchData)
    setUser(fetchData.data)
    localStorage.setItem('user', JSON.stringify(fetchData.data))
    return true
  }

  const deleteUser = () => {
    setUser(null)
    localStorage.removeItem('user')
  }

  const login = token => sendRequest('POST', 'auth', { token }, saveUser)

  const logout = () => sendRequest('POST', 'logout', undefined, deleteUser)

  const getUser = () => {
    if (!user.id) return
    return sendRequest('GET', `get-guardians/${user.id}`, undefined, saveUser)
  }

  return <AuthContext.Provider value={{ user, getUser, login, logout }}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { useAuth, AuthProvider }
