import tw, { css } from 'twin.macro'

export const root = css`
  /* html {
    opacity: 0;
    transform: opacity 0.1s ease;
    &.wf-active {
      opacity: 1;
    }
  } */
  body {
    ${tw`font-sans antialiased text text-base leading-none`}
  }
  p {
    ${tw`leading-tight mb-4`}
    &:last-of-type {
      ${tw`mb-0`}
    }

    a {
      ${tw`underline`}
    }
  }

  /* input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input[type='checkbox'] + label::after {
    content: '\2714';
    color: #2c3e50;
    line-height: 1.5;
    text-align: center;
  }

  /* :checked */
  input[type='checkbox']:checked + label::before {
    background: #fff;
    border-color: #fff;
  }
  input[type='checkbox'] + label::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  input[type='checkbox']:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  /* Transition */
  label::before,
  label::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  } */
`
