// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-confirm-email-js": () => import("./../../../src/pages/confirm-email.js" /* webpackChunkName: "component---src-pages-confirm-email-js" */),
  "component---src-pages-confirm-request-js": () => import("./../../../src/pages/confirm-request.js" /* webpackChunkName: "component---src-pages-confirm-request-js" */),
  "component---src-pages-guardian-information-js": () => import("./../../../src/pages/guardian-information.js" /* webpackChunkName: "component---src-pages-guardian-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

